import { lazy } from 'react';
import Roles from '../../common/Roles';

const UserPointList = lazy(() => import('../../pages/user_points/UserPointList'));
const UserPointDetails = lazy(() => import('../../pages/user_points/UserPointDetails'));
const UserPointConversionList = lazy(() => import('../../pages/user_points/UserPointConversionList'));
const UserPointRequests = lazy(() => import('../../pages/user_points/UserPointRequests'));
const ExportUserPoints = lazy(() => import('../../pages/user_points/export_user_points/ExportUserPoints'));
const TargetPoints = lazy(() => import('../../pages/user_points/target_points/TargetPoints'));
const ReferralPointsList = lazy(() => import('../../pages/user_points/referral_points/ReferralPointsList'));
const ReferralPointsDetails = lazy(() => import('../../pages/user_points/referral_points/ReferralPointsDetails'));


export const UserPointsRoutes = [
  {
    path: '/users/points_list',
    exact: true,
    component: UserPointList,
    meta: {
      permissions: [Roles.admin],
    },
  },
  {
    path: '/user/:id/point_details',
    exact: true,
    component: UserPointDetails,
    meta: {
      permissions: [Roles.admin],
    },
  },
  {
    path: '/users/points_requests',
    exact: true,
    component: UserPointRequests,
    meta: {
      permissions: [Roles.admin],
    },
  },
  {
    path: '/users/points_conversion_list',
    exact: true,
    component: UserPointConversionList,
    meta: {
      permissions: [Roles.admin],
    },
  },
  {
    path: '/users/export_user_points',
    exact: true,
    component: ExportUserPoints,
    meta: {
      permissions: [Roles.admin],
    },
  },
  {
    path: '/users/target_points',
    exact: true,
    component: TargetPoints,
    meta: {
      permissions: [Roles.admin],
    },
  },
  {
    path: '/users/referral_points_list',
    exact: true,
    component: ReferralPointsList,
    meta: {
      permissions: [Roles.admin],
    },
  },
  {
    path: '/users/:id/referral_points_details',
    exact: true,
    component: ReferralPointsDetails,
    meta: {
      permissions: [Roles.admin],
    },
  },
  
]
