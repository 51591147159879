import { lazy } from 'react';
import Roles from '../../common/Roles';

const Settings = lazy(() => import('../../pages/settings/Settings'));
const PaymentKey = lazy(() => import('../../pages/settings/PaymentKey'));
const PaypalPaymentKey = lazy(() => import('../../pages/settings/ChangePaypalKey'));
const StripePaymentKey = lazy(() => import('../../pages/settings/ChangeStripeKey'));
const NotificationMail = lazy(() => import('../../pages/settings/NotificationMail'));

const DeliveryCost = lazy(() => import('../../pages/delivery_cost/DeliveryCost'));
const ShoppingPoints = lazy(() => import('../../pages/shopping_points/ShoppingPoints'));
const ShoppingCoinToCoupon = lazy(() => import('../../pages/shopping_points/ShoppingCoinToCoupon'));
const BranchUserList = lazy(() => import('../../pages/settings/branch_user/BranchUserList'));
const BranchUserCreate = lazy(() => import('../../pages/settings/branch_user/BranchUserCreate'));
const ProductAttributes = lazy(() => import('../../pages/settings/ProductAttributes'));
const ReferralPoints = lazy(() => import('../../pages/settings/ReferralPoints'));
const ProductPoints = lazy(() => import('../../pages/settings/product_points/ProductPointsList'));

export const SettingsRoutes = [
  {
    path: '/settings/max_item',
    exact: true,
    component: Settings,
    meta: {
      permissions: [Roles.admin],
    },
  },
  {
    path: '/settings/payment_key',
    exact: true,
    component: PaymentKey,
    meta: {
      permissions: [Roles.admin],
    },
  },
  {
    path: '/settings/change_paypal_payment_key',
    exact: true,
    component: PaypalPaymentKey,
    meta: {
      permissions: [Roles.admin],
    },
  },
  {
    path: '/settings/change_stripe_payment_key',
    exact: true,
    component: StripePaymentKey,
    meta: {
      permissions: [Roles.admin],
    },
  },
 
  {
    path: '/settings/admin_mail',
    exact: true,
    component: NotificationMail,
    meta: {
      permissions: [Roles.admin],
    },
  },
  {
    path: '/settings/delivery_cost',
    exact: true,
    component: DeliveryCost,
    meta: {
      permissions: [Roles.admin],
    },
  },
  {
    path: '/settings/product_points',
    exact: true,
    component: ProductPoints,
    meta: {
      permissions: [Roles.admin],
    },
  },
  {
    path: '/settings/referral_points',
    exact: true,
    component: ReferralPoints,
    meta: {
      permissions: [Roles.admin],
    },
  },
  {
    path: '/settings/shopping_points',
    exact: true,
    component: ShoppingPoints,
    meta: {
      permissions: [Roles.admin],
    },
  },
  {
    path: '/settings/shopping_points_to_coupon',
    exact: true,
    component: ShoppingCoinToCoupon,
    meta: {
      permissions: [Roles.admin],
    },
  },
  {
    path: '/settings/branch_users_list',
    exact: true,
    component: BranchUserList,
    meta: {
      permissions: [Roles.admin],
    },
  },
  {
    path: '/settings/branch_users_create',
    exact: true,
    component: BranchUserCreate,
    meta: {
      permissions: [Roles.admin],
    },
  },
  {
    path: '/settings/branch_users_edit/:id',
    exact: true,
    component: BranchUserCreate,
    meta: {
      permissions: [Roles.admin],
    },
  },
  
  // products attributes
  {
    path: '/settings/create_product_attributes',
    exact: true,
    component: ProductAttributes,
    meta: {
      permissions: [Roles.admin],
    },
  },
]
