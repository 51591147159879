// import React, { Component } from 'react';
// import { Switch, Route } from 'react-router-dom';
// import { ProtectedRoute } from "./ProtectedRoute";

// import Login from '../pages/login/LoginPage'
// import Home from '../pages/home/HomePage'
// import NotFound from '../pages/wrong_route/PageNotFound'

// import EventCreate from '../pages/events/EventCreate';
// import EventList from '../pages/events/EventList';
// import EventDetails from '../pages/events/EventDetails';
// import EventUpdate from '../pages/events/EventUpdate';

// import MessageSend from '../pages/communication/MessageSend';
// import MessageList from '../pages/communication/MessageList';
// import MessageUpdate from '../pages/communication/MessageUpdate';
// import MessageDetails from '../pages/communication/MessegeDetails'

// import NewsCreate from '../pages/news/NewsCreate';
// import NewsList from '../pages/news/NewsList';
// import NewsDetails from '../pages/news/NewsDetails';
// import NewsUpdate from '../pages/news/NewsUpdate';

// import Notice from '../pages/notice/Notice';
// import Privacy from '../pages/privacy/Privacy';

// import Contact from '../pages/contact/Contact';
// import ContactAdd from '../pages/contact/ContactAdd';

// import ReviewsCreate from '../pages/reviews/ReviewsCreate';
// import ReviewsList from '../pages/reviews/ReviewsList';
// import ReviewDetails from '../pages/reviews/ReviewDetails';
// import ReviewUpdate from '../pages/reviews/ReviewUpdate';

// class Routers extends Component {
//     render() {
//         return (
//             <div>
//                 <Switch>
//                     <Route exact path="/" component={Login} />
//                     <Route exact path="/home" component={Home} />

//                     <ProtectedRoute exact path="/event/create" component={EventCreate} />
//                     <ProtectedRoute exact path="/event/list" component={EventList} />
//                     <ProtectedRoute exact path="/event/:id/details" component={EventDetails} />
//                     <ProtectedRoute exact path="/event/:id/edit" component={EventUpdate} />

//                     <ProtectedRoute exact path="/message/send" component={MessageSend} />
//                     <ProtectedRoute exact path="/message/list" component={MessageList} />
//                     <ProtectedRoute exact path="/message/:id/details" component={MessageDetails} />
//                     <ProtectedRoute exact path="/message/:id/edit" component={MessageUpdate} />

//                     <ProtectedRoute exact path="/news/send" component={NewsCreate} />
//                     <ProtectedRoute exact path="/news/list" component={NewsList} />
//                     <ProtectedRoute exact path="/news/:id/details" component={NewsDetails} />
//                     <ProtectedRoute exact path="/news/:id/edit" component={NewsUpdate} />

//                     <ProtectedRoute exact path="/notice" component={Notice} />
//                     <ProtectedRoute exact path="/privacy" component={Privacy} />

//                     <ProtectedRoute exact path="/reviews/create" component={ReviewsCreate} />
//                     <ProtectedRoute exact path="/reviews/list" component={ReviewsList} />
//                     <ProtectedRoute exact path="/reviews/:id/details" component={ReviewDetails} />
//                     <ProtectedRoute exact path="/reviews/:id/edit" component={ReviewUpdate} />

//                     <ProtectedRoute exact path="/contact/update/:id/:type" component={ContactAdd} />
//                     <ProtectedRoute exact path="/contact" component={Contact} />

//                     <ProtectedRoute exact component={NotFound} />
//                 </Switch>

//             </div>
//         );
//     }
// }

// export default Routers;

import React, { Component, lazy, Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import  ProtectedRoute  from "./ProtectedRouteBackup";

const Login = lazy(() => import('../pages/login/LoginPage'));
const Home = lazy(() => import('../pages/home/HomePage'));
const ProductHome = lazy(() => import('../pages/home/product_graph/ProductHome'));
const OrderHome = lazy(() => import('../pages/home/order_graph/OrderHome'));
const NotFound = lazy(() => import('../pages/wrong_route/PageNotFound'));

const EventCreate = lazy(() => import('../pages/events/EventCreate'));
const EventList = lazy(() => import('../pages/events/EventList'));
const EventDetails = lazy(() => import('../pages/events/EventDetails'));
const EventUpdate = lazy(() => import('../pages/events/EventUpdate'));

const MessageSend = lazy(() => import('../pages/communication/MessageSend'));
const MessageList = lazy(() => import('../pages/communication/MessageList'));
const MessageUpdate = lazy(() => import('../pages/communication/MessageUpdate'));
const MessageDetails = lazy(() => import('../pages/communication/MessegeDetails'));

const NewsCreate = lazy(() => import('../pages/news/NewsCreate'));
const NewsList = lazy(() => import('../pages/news/NewsList'));
const NewsDetails = lazy(() => import('../pages/news/NewsDetails'));
const NewsUpdate = lazy(() => import('../pages/news/NewsUpdate'));

const Notice = lazy(() => import('../pages/notice/Notice'));
const Privacy = lazy(() => import('../pages/privacy/Privacy'));

const Contact = lazy(() => import('../pages/contact/Contact'));
const ContactAdd = lazy(() => import('../pages/contact/ContactAdd'));

const ReviewsCreate = lazy(() => import('../pages/reviews/ReviewsCreate'));
const ReviewsList = lazy(() => import('../pages/reviews/ReviewsList'));
const ReviewDetails = lazy(() => import('../pages/reviews/ReviewDetails'));
const ReviewUpdate = lazy(() => import('../pages/reviews/ReviewUpdate'));



const FlyerCreate = lazy(() => import('../pages/flyer/FlyerCreate'));
const FlyerList = lazy(() => import('../pages/flyer/FlyerList'));
// const EventDetails = lazy(() => import('../pages/events/EventDetails'));
const FlyerUpdate = lazy(() => import('../pages/flyer/FlyerUpdate'));

const SalesPointUploadType = lazy(() => import('../pages/sales_points/SalesPointUploadType'));
const SalesPointsList = lazy(() => import('../pages/sales_points/SalesPointsList'));
const SalesPointDetails = lazy(() => import('../pages/sales_points/SalesPointsDetails'));
const SalesPointsUpdate = lazy(() => import('../pages/sales_points/SalesPointsUpdate'));

const ProductExcelUplaod = lazy(() => import('../pages/product_excel/ProductExcelUpload'));
const ExcelProductList = lazy(() => import('../pages/product_excel/ExcelProductList'));
const ExcelProductDetails = lazy(() => import('../pages/product_excel/ExcelProductDetails'));
const ExcelProductUpdate = lazy(() => import('../pages/product_excel/ExcelProductUpdate'));
const ProductImageUpload = lazy(() => import('../pages/product_excel/ProductImageUpload'));
const ProductImageList = lazy(() => import('../pages/product_excel/ProductImageList'));

const TicketsList = lazy(() => import('../pages/tickets/TicketsList'));
const TicketDetails = lazy(() => import('../pages/tickets/TicketDetails'));

const ProductInfoCreate = lazy(() => import('../pages/product_info/ProductInfoCreate'));
const ProductInfoList = lazy(() => import('../pages/product_info/ProductInfoList'));
const ProductInfoDetails = lazy(() => import('../pages/product_info/ProductInfoDetails'));
const ProductInfoUpdate = lazy(() => import('../pages/product_info/ProductInfoUpdate'));

const LastMinuteOfferCreate = lazy(() => import('../pages/last_minute_offer/LastMinuteOfferCreate'));
const LastMinuteOfferList = lazy(() => import('../pages/last_minute_offer/LastMinuteOfferList'));
const LastMinuteOfferDetails = lazy(() => import('../pages/last_minute_offer/LastMinuteOfferDetails'));
const LastMinuteOfferUpdate = lazy(() => import('../pages/last_minute_offer/LastMinuteOfferUpdate'));

const SurveySetList = lazy(() => import('../pages/advance_profiling/SurveySetList'));
const SurveyCreate = lazy(() => import('../pages/advance_profiling/SurveyCreate'));
const SurveyUpdate = lazy(() => import('../pages/advance_profiling/SurveyUpdate'));
const SurveyAddQuestions = lazy(() => import('../pages/advance_profiling/SurveyAddQuestions'));
const SurveyDetails = lazy(() => import('../pages/advance_profiling/SurveyDetails'));

const OrderList = lazy(() => import('../pages/product_order/OrderList'));
const OrderDetails = lazy(() => import('../pages/product_order/OrderDetails'));

const DeliverySlotCreate = lazy(() => import('../pages/delivery_slot/DeliverySlotCreate'));

const SpecialDeliveryDate = lazy(() => import('../pages/delivery_slot/SpecialDeliveryDate'));

const Settings = lazy(() => import('../pages/settings/Settings'));
const PaymentKey = lazy(() => import('../pages/settings/PaymentKey'));
const PaypalPaymentKey = lazy(() => import('../pages/settings/ChangePaypalKey'));
const StripePaymentKey = lazy(() => import('../pages/settings/ChangeStripeKey'));
const NotificationMail = lazy(() => import('../pages/settings/NotificationMail'));
const BranchUserList = lazy(() => import('../pages/settings/branch_user/BranchUserList'));
const BranchUserCreate = lazy(() => import('../pages/settings/branch_user/BranchUserCreate'));

const FidelityCardRequestList = lazy(() => import('../pages/fidelity_card_request/FidelityCardRequestList'));
const FidelityRequestCardDetails = lazy(() => import('../pages/fidelity_card_request/FidelityCardDetails'));


const FidelityCardUpload = lazy(() => import('../pages/fidelity_card/FidelityCardUpload'));
const FidelityCardList = lazy(() => import('../pages/fidelity_card/FidelityCardList'));
const FidelityCardDetails = lazy(() => import('../pages/fidelity_card/FidelityCardDetails'));
const NewChatProductList = lazy(() => import('../pages/product_order/NewChatProductList'));

const ChangePassword = lazy(() => import('../pages/my_account/ChangePassword'));
const SecurityQuestions = lazy(() => import('../pages/my_account/SecurityQuestions'));

const ForgotPassword = lazy(() => import('../pages/forgot_password/ForgotPassword'));
const NewPassword = lazy(() => import('../pages/forgot_password/NewPassword'));

const LanguageChangeRefresh = lazy(() => import('../pages/refresh_route/LanguageChangeRefresh'));
const PrivacyHistory = lazy(() => import('../pages/privacy/PrivacyHistory'));
const UserList = lazy(() => import('../pages/privacy/UserList'));

const OrderDetailsPrint = lazy(() => import('../pages/product_order/OrderDetailsPrint'));

const HelpList = lazy(() => import('../pages/help/HelpList'));
const CouponsUpload = lazy(() => import('../pages/coupons/CouponsUpload'));
const CouponsList = lazy(() => import('../pages/coupons/CouponsList'));
const DeliveryCost = lazy(() => import('../pages/delivery_cost/DeliveryCost'));
const ShoppingPoints = lazy(() => import('../pages/shopping_points/ShoppingPoints'));
const ShoppingCoinToCoupon = lazy(() => import('../pages/shopping_points/ShoppingCoinToCoupon'));

const UserPointList = lazy(() => import('../pages/user_points/UserPointList'));
const UserPointDetails = lazy(() => import('../pages/user_points/UserPointDetails'));
const UserPointConversionList = lazy(() => import('../pages/user_points/UserPointConversionList'));
const UserPointRequests = lazy(() => import('../pages/user_points/UserPointRequests'));
const ExportUserPoints = lazy(() => import('../pages/user_points/export_user_points/ExportUserPoints'));

class Routers extends Component {
    render() {
        return (
            <div>
                <Switch>

                    <Suspense fallback={<div className="loader"></div>}>
                        <Route exact path="/" component={Login} />
                        <Route exact path="/forgot_password" component={ForgotPassword} />
                        <Route exact path="/forgot_password/change_password" component={NewPassword} />

                        <ProtectedRoute exact path="/home" component={Home} />
                        <ProtectedRoute exact path="/product/graph" component={ProductHome} />
                        <ProtectedRoute exact path="/order/graph" component={OrderHome} />
                        <ProtectedRoute exact path="/event/create" component={EventCreate} />
                        <ProtectedRoute exact path="/event/list" component={EventList} />
                        <ProtectedRoute exact path="/event/:id/details" component={EventDetails} />
                        <ProtectedRoute exact path="/event/:id/edit" component={EventUpdate} />

                        <ProtectedRoute exact path="/message/send" component={MessageSend} />
                        <ProtectedRoute exact path="/message/list" component={MessageList} />
                        <ProtectedRoute exact path="/message/:id/details" component={MessageDetails} />
                        <ProtectedRoute exact path="/message/:id/edit" component={MessageUpdate} />

                        <ProtectedRoute exact path="/news/send" component={NewsCreate} />
                        <ProtectedRoute exact path="/news/list" component={NewsList} />
                        <ProtectedRoute exact path="/news/:id/details" component={NewsDetails} />
                        <ProtectedRoute exact path="/news/:id/edit" component={NewsUpdate} />

                        <ProtectedRoute exact path="/notice" component={Notice} />
                        <ProtectedRoute exact path="/privacy" component={Privacy} />

                        <ProtectedRoute exact path="/reviews/create" component={ReviewsCreate} />
                        <ProtectedRoute exact path="/reviews/list" component={ReviewsList} />
                        <ProtectedRoute exact path="/reviews/:id/details" component={ReviewDetails} />
                        <ProtectedRoute exact path="/reviews/:id/edit" component={ReviewUpdate} />

                        <ProtectedRoute exact path="/contact/update/:id/:type" component={ContactAdd} />
                        <ProtectedRoute exact path="/contact" component={Contact} />

                        <ProtectedRoute exact path="/flyer/create" component={FlyerCreate} />
                        <ProtectedRoute exact path="/flyer/list" component={FlyerList} />
                        <ProtectedRoute exact path="/flyer/:id/edit" component={FlyerUpdate} />

                        <ProtectedRoute exact path="/sales_points/upload" component={SalesPointUploadType} />
                        <ProtectedRoute exact path="/sales_points/list" component={SalesPointsList} />
                        <ProtectedRoute exact path="/sales_points/:id/details" component={SalesPointDetails} />
                        <ProtectedRoute exact path="/sales_points/:id/edit" component={SalesPointsUpdate} />

                        <ProtectedRoute exact path="/product/upload" component={ProductExcelUplaod} />
                        <ProtectedRoute exact path="/product/list" component={ExcelProductList} />
                        <ProtectedRoute exact path="/product/:id/details" component={ExcelProductDetails} />
                        <ProtectedRoute exact path="/product/:id/edit" component={ExcelProductUpdate} />
                        <ProtectedRoute exact path="/product/image/upload" component={ProductImageUpload} />
                        <ProtectedRoute exact path="/product/image/list" component={ProductImageList} />

                        <ProtectedRoute exact path="/tickets/list" component={TicketsList} />
                        <ProtectedRoute exact path="/tickets/:id/details" component={TicketDetails} />

                        <ProtectedRoute exact path="/create_delivery_slot" component={DeliverySlotCreate} />
                        <ProtectedRoute exact path="/special_delivery_dates" component={SpecialDeliveryDate} />

                        <ProtectedRoute exact path="/product/info/create" component={ProductInfoCreate} />
                        <ProtectedRoute exact path="/product/info/list" component={ProductInfoList} />
                        <ProtectedRoute exact path="/product/info/:id/details" component={ProductInfoDetails} />
                        <ProtectedRoute exact path="/product/info/:id/edit" component={ProductInfoUpdate} />

                        <ProtectedRoute exact path="/lastminute/offer/create" component={LastMinuteOfferCreate} />
                        <ProtectedRoute exact path="/lastminute/offer/list" component={LastMinuteOfferList} />
                        <ProtectedRoute exact path="/lastminute/offer/:id/details" component={LastMinuteOfferDetails} />
                        <ProtectedRoute exact path="/lastminute/offer/:id/edit" component={LastMinuteOfferUpdate} />

                        <ProtectedRoute exact path="/survey/list" component={SurveySetList} />
                        <ProtectedRoute exact path="/survey/create" component={SurveyCreate} />
                        <ProtectedRoute exact path="/survey/:id/edit" component={SurveyUpdate} />
                        <ProtectedRoute exact path="/survey/:id/questions/create" component={SurveyAddQuestions} />
                        <ProtectedRoute exact path="/survey/:id/details" component={SurveyDetails} />

                        <ProtectedRoute exact path="/product/order/list" component={OrderList} />
                        <ProtectedRoute exact path="/product/order/:id/details" component={OrderDetails} />
                        <ProtectedRoute exact path="/product/order/list/notifications" component={NewChatProductList} />

                        <ProtectedRoute exact path="/settings/max_item" component={Settings} />
                        <ProtectedRoute exact path="/settings/payment_key" component={PaymentKey} />
                        <ProtectedRoute exact path="/settings/change_paypal_payment_key" component={PaypalPaymentKey} />
                        <ProtectedRoute exact path="/settings/change_stripe_payment_key" component={StripePaymentKey} />
                        <ProtectedRoute exact path="/settings/admin_mail" component={NotificationMail} />

                        <ProtectedRoute exact path="/fidelity/card/requests" component={FidelityCardRequestList} />
                        <ProtectedRoute exact path="/fidelity/card/requests/:id/details" component={FidelityRequestCardDetails} />

                        <ProtectedRoute exact path="/fidelity/card/upload" component={FidelityCardUpload} />
                        <ProtectedRoute exact path="/fidelity/card/list" component={FidelityCardList} />
                        <ProtectedRoute exact path="/fidelity/card/:id/details" component={FidelityCardDetails} />

                        <ProtectedRoute exact path="/my_account/change_password" component={ChangePassword} />
                        <ProtectedRoute exact path="/my_account/security_questions" component={SecurityQuestions} />

                        <ProtectedRoute exact path="/refresh" component={LanguageChangeRefresh} />

                        <ProtectedRoute exact path="/privacy/privacy_list" component={PrivacyHistory} />
                        <ProtectedRoute exact path="/privacy_accept/user_list" component={UserList} />

                        <ProtectedRoute exact path="/product/order/:id/OrderDetailsPrint" component={OrderDetailsPrint} />

                        <ProtectedRoute exact path="/help/help_and_various" component={HelpList} />

                        <ProtectedRoute exact path="/coupon/coupon_upload" component={CouponsUpload} />
                        <ProtectedRoute exact path="/coupon/coupon_list" component={CouponsList} /> 

                        <ProtectedRoute exact path="/settings/delivery_cost" component={DeliveryCost} /> 

                        <ProtectedRoute exact path="/settings/shopping_points" component={ShoppingPoints} />  
                        <ProtectedRoute exact path="/settings/shopping_points_to_coupon" component={ShoppingCoinToCoupon} />  
                        <ProtectedRoute exact path="/settings/branch_users_list" component={BranchUserList} />  
                        <ProtectedRoute exact path="/settings/branch_users_create" component={BranchUserCreate} />  
                        <ProtectedRoute exact path="/settings/branch_users_edit/:id" component={BranchUserCreate} />  

                        <ProtectedRoute exact path="/users/points_list" component={UserPointList} />
                        <ProtectedRoute exact path="/user/:id/point_details" component={UserPointDetails} />

                        <ProtectedRoute exact path="/users/points_requests" component={UserPointRequests} />
                        <ProtectedRoute exact path="/users/points_conversion_list" component={UserPointConversionList} />

                        <ProtectedRoute exact path="/users/export_user_points" component={ExportUserPoints} />

                    </Suspense>
                    <ProtectedRoute exact component={NotFound} />
                </Switch>

            </div>
        );
    }
}

export default Routers; 