import moment from 'moment';
import Constants from "./Constants";

export const statusColor = (statusId) => {
    if (statusId === Constants.POINT_STATUS.approved) return 'success'
    if (statusId === Constants.POINT_STATUS.rejected) return 'danger'
    if (statusId === Constants.POINT_STATUS.earned) return 'primary'
    if (statusId === 4) return 'info'
    if (statusId === 5) return 'warning'
    if (statusId === 6) return 'dark'
    if (statusId === 7) return 'light'
    if (statusId === 8) return 'warning'
    return 'secondary'
}

export const statusName = (statusId) => {
  if (statusId === Constants.POINT_STATUS.approved) return 'Approved'
  if (statusId === Constants.POINT_STATUS.rejected) return 'Rejected'
  return 'Pending'
}

export const dateFormat = (inputDate) => {
  let date, month, year;

  date = inputDate.getDate();
  month = inputDate.getMonth() + 1;
  year = inputDate.getFullYear();

    date = date
        .toString()
        .padStart(2, '0');

    month = month
        .toString()
        .padStart(2, '0');

  return `${date}-${month}-${year}`;
}

export const matchRoute = (route='', url='') => {
  const splitRoute = route.split(':id');
  if (splitRoute.length === 1) return route;
  return splitRoute[0] +( url.match(/\/\d+\/+/)?.[0].match(/\d+/)[0] || url.match(/\/\d+/)?.[0].match(/\d+/)[0]) + splitRoute[1];
}

export const currentPath = () => window.location.pathname;

export const showDate = (date, format = 'DD-MM-YYYY hh:mm:ss') => date ? moment(date).format(format) : '---';