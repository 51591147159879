export default class Constants {
    static MODULE_Home = 1;
    static MODULE_Fidelity_Card_Request = 2;
    static MODULE_Fidelity_Card = 3;
    static MODULE_Product = 4;
    static MODULE_Product_Info = 5;
    static MODULE_Product_Orders = 6;
    static MODULE_Last_Minute_Offer = 7;
    static MODULE_Communication = 8;
    static MODULE_Institutional_Flyers = 9;
    static MODULE_Sales_Points = 10;
    static MODULE_Delivery_Slot = 11;
    static MODULE_Ticket_Management = 12;
    static MODULE_News = 13;
    static MODULE_Events = 14;
    static MODULE_Reviews = 15;
    static MODULE_Advance_Profiling = 16;
    static MODULE_Notice = 17;
    static MODULE_Contact = 18;
    static MODULE_Privacy_Agreement = 19;
    static MODULE_Settings = 20;
    static MODULE_My_Account = 21;

    static EXPORT_FAILED = 3;
    static EXPORT_COMPLETED = 2;
    static EXPORT_START = 1;

    static ORDER_STATUS_APPROVED_BY_ADMIN = 2;

    static POINT_STATUS = {
        earned: 0,
        pending: 1,
        approved: 2,
        rejected: 3,
    };

    static GRAPH_IDS = {
        //home page graphs
        app_downloads: 1,
        fidelity_card_request: 2,
        fidelity_card: 3,
        fidelity_card_pdv: 4,
        fidelity_card_province: 5,
        fidelity_card_family_member: 6,
        fidelity_card_gender: 7,
        product_info_list: 8,
        institutional_flyers: 9,
        last_minute_offer: 10,
        sales_point: 11,
        ticket_management: 12,
        news_list: 13,
        events: 14,
        communication: 15,
        total_questions_per_review_set: 16,
        total_questions_per_advance_profiling_set: 17,

        //products graphs
        product_sales: 18,
        top_5_product: 19,
        categories: 20,
        subcategories: 21,

        //orders graphs
        total_order_on_status: 22,
        total_order_delivered_per_month: 23,
        total_order_delivered_per_day: 24,
        total_value_of_order_delivered_per_month: 25,
        total_order_value_delivered_per_month: 26,
        total_order_value_delivered_per_day: 27,
        total_value: 28,
        total_item_promo_act_used: 29,
        total_order_per_item_no: 30,
        total_order_per_slot_home_delivery: 31,
        total_order_per_slot_store_delivery: 32,

        //customer analysis graphs
        favorite_products: 33,
        essential_products: 34,
        last_5_Order: 35,
        customers_orders: 36,
        top_10_product: 37,
    }

}